/* eslint-disable react/prop-types */
import { withTranslation } from 'react-i18next';
import { Col, Row } from 'reactstrap';
import Date from './Date';
import React from "react";

const CommunicationFilter = ({ t, filters, legendDate, activeTab }) => {

  return (
    <Row className="g-4 flex-column flex-xxl-row">
      <Col xl={12}>
        <Row className="g-3 mb-4 row-cols-1 row-cols-md-3">
          {
            activeTab != 5 ?
              <Col>
                <Date
                  t={t}
                  filters={filters}
                  placeholder={legendDate || t("shipping-date")}
                  item={{ field: 'created_at' }}
                />
              </Col>
              :
              <Col style={{marginBottom: '38px'}}>
              </Col>
          }
        </Row>
      </Col>
    </Row>
  );
};

export default withTranslation()(CommunicationFilter);
