/* eslint-disable react/prop-types */
import React, { } from 'react';
import { withTranslation } from 'react-i18next';
import { Row, Col } from 'reactstrap';
import Card from '../../components/Card';
import { t } from 'i18next';
import StackedChart from '../../components/StackedChart';

const General = ({ communication, buildUrlWithFilters, chartOptions, type, setActiveTab, index }) => {

  return (
    <>
      <h5 className="mb-1">{t('results-manager.communication-summary.titles.general')}</h5>

      <Row>
        <Col xs={12} md={6} xl={4}>
          {communication?.cards?.general?.length ? (
            <Card
              title={t('results-manager.communication-summary.cards.total')}
              data={communication?.cards?.general[0]}
              icon="ri-inbox-fill text-info"
              type={type}
              index={index}
              dontChangeTab={true}
              dontNeedFormat={true}
              setActiveTab={setActiveTab}
              color="bg-info-subtle"
              label={"Total de comunicações disparadas no período."}
            />
          ) : (
            <div className="skeleton card" style={{ height: '144px' }} />
          )}
        </Col>
        <Col xs={12} md={6} xl={4}>
          {communication?.cards?.general?.length ? (
            <Card
              title={t('results-manager.communication-summary.cards.success')}
              data={communication?.cards?.general[1]}
              icon="ri-inbox-fill text-success"
              type={type}
              index={index}
              dontChangeTab={true}
              dontNeedFormat={true}
              setActiveTab={setActiveTab}
              color="bg-success-subtle"
              label={"Total de comunicações disparadas com sucesso no período."}
            />
          ) : (
            <div className="skeleton card" style={{ height: '144px' }} />
          )}
        </Col>
        <Col xs={12} md={6} xl={4}>
          {communication?.cards?.general?.length ? (
            <Card
              title={t('results-manager.communication-summary.cards.fail')}
              data={communication?.cards?.general[2]}
              icon="ri-inbox-fill text-danger"
              type={type}
              index={index}
              dontChangeTab={true}
              dontNeedFormat={true}
              setActiveTab={setActiveTab}
              color="bg-danger-subtle"
              label={"Total de comunicações disparadas com erro no período."}
            />
          ) : (
            <div className="skeleton card" style={{ height: '144px' }} />
          )}
        </Col>
      </Row>
      {
        chartOptions &&
        <StackedChart communication={communication} chartOptions={chartOptions} type={type} />
      }
    </>
  );
};

export default withTranslation()(General);
