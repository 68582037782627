/* eslint-disable react/prop-types */
import React, { } from 'react';
import { withTranslation } from 'react-i18next';
import { Row, Col } from 'reactstrap';
import Card from '../../components/Card';
import { t } from 'i18next';
import StackedChart from '../../components/StackedChart';

const CashbackCredits = ({ credits, chartOptions, type, setActiveTab, index }) => {

  return (
    <>
      <h5 className="mb-1">{t('results-manager.credits-summary.titles.cashback-credits')}</h5>

      <Row>
        <Col xs={12} md={6} xl={4}>
          {credits?.cards?.cashback?.length ? (
            <Card
              title={t('results-manager.credits-summary.cards.generated')}
              data={credits?.cards?.cashback[0]}
              icon="ri-refund-fill text-info"
              type={type}
              index={index}
              setActiveTab={setActiveTab}
              color="bg-info-subtle"
              customSubtitle={"Nº de créditos gerados: "}
              label={"Soma dos valores de crédito gerados no período selecionado."}
            />
          ) : (
            <div className="skeleton card" style={{ height: '144px' }} />
          )}
        </Col>
        <Col xs={12} md={6} xl={4}>
          {credits?.cards?.cashback?.length ? (
            <Card
              title={t('results-manager.credits-summary.cards.made-available')}
              data={credits?.cards?.cashback[1]}
              icon="ri-refund-fill text-success"
              type={type}
              index={index}
              setActiveTab={setActiveTab}
              color="bg-success-subtle"
              customSubtitle={"Nº de créditos resgatados: "}
              label={"Soma dos valores de crédito disponibilizados no período selecionado."}
            />
          ) : (
            <div className="skeleton card" style={{ height: '144px' }} />
          )}
        </Col>
        <Col xs={12} md={6} xl={4}>
          {credits?.cards?.cashback?.length ? (
            <Card
              title={t('results-manager.credits-summary.cards.expired')}
              data={credits?.cards?.cashback[2]}
              icon="ri-refund-fill text-danger"
              type={type}
              index={index}
              setActiveTab={setActiveTab}
              color="bg-danger-subtle"
              customSubtitle={"Nº de créditos expirados: "}
              label={"Soma dos valores de crédito expirados no período selecionado."}
            />
          ) : (
            <div className="skeleton card" style={{ height: '144px' }} />
          )}
        </Col>
      </Row>
      {
        chartOptions &&
        <StackedChart communication={credits} chartOptions={chartOptions} type={type} />
      }
    </>
  );
};

export default withTranslation()(CashbackCredits);
