/* eslint-disable react/prop-types */
import React, { } from 'react';
import { withTranslation } from 'react-i18next';
import Billing from './Billing';
import SalesNumber from './SalesNumber';
import AverageTicket from './AverageTicket';

const Summary = ({ sales, buildUrlWithFilters, setActiveTab }) => {

  return (
    <>
      <div>
        <Billing sales={sales} setActiveTab={setActiveTab} index={'2'} buildUrlWithFilters={buildUrlWithFilters} />
      </div>

      <div>
        <SalesNumber sales={sales} setActiveTab={setActiveTab} index={'3'} buildUrlWithFilters={buildUrlWithFilters} />
      </div>

      <div>
        <AverageTicket sales={sales} setActiveTab={setActiveTab} index={'4'} buildUrlWithFilters={buildUrlWithFilters} />
      </div>
    </>
  );
};

export default withTranslation()(Summary);
