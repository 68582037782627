/* eslint-disable react/prop-types */
import React from 'react'
import ReactApexChart from 'react-apexcharts'
import { Row, Col, Card as CardWrapper, CardBody } from 'reactstrap';

export default function StackedChartCommunication({ communication, chartOptions, type }) {

  return (  
    <Row>
      <Col>
        <CardWrapper>
          <CardBody>
            {!communication?.chart?.isLoading ? (
              <ReactApexChart
                key={type}
                type="bar"
                height={550}
                options={chartOptions}
                series={[
                  {
                    name: 'Sucesso',
                    type: 'bar',
                    data: communication?.chart?.data?.map((i) =>
                      i?.successes
                    ),
                  },
                  {
                    name: 'Erro',
                    type: 'bar',
                    data: communication?.chart?.data?.map((i) =>
                      i?.errors
                    ),
                  },
                ]}
              />
            ) : (
              <div className="skeleton card" style={{ height: 550 }} />
            )}
          </CardBody>
        </CardWrapper>
      </Col>
    </Row>
  )
}
