/* eslint-disable react/prop-types */
import React, { } from 'react';
import { withTranslation } from 'react-i18next';
import TotalCredits from './TotalCredits';
import CashbackCredits from './CashbackCredits';
import IncentiveCredits from './IncentiveCredits';
import AvailableCredits from './AvailableCredits';

const Summary = ({ credits, buildUrlWithFilters, setActiveTab }) => {

  return (
    <>
      <div>
        <TotalCredits credits={credits} setActiveTab={setActiveTab} index={'2'} buildUrlWithFilters={buildUrlWithFilters} />
      </div>

      <div>
        <CashbackCredits credits={credits} setActiveTab={setActiveTab} index={'3'} buildUrlWithFilters={buildUrlWithFilters} />
      </div>

      <div>
        <IncentiveCredits credits={credits} setActiveTab={setActiveTab} index={'4'} buildUrlWithFilters={buildUrlWithFilters} />
      </div>

      <div>
        <AvailableCredits credits={credits} setActiveTab={setActiveTab} index={'4'} buildUrlWithFilters={buildUrlWithFilters} />
      </div>

    </>
  );
};

export default withTranslation()(Summary);
