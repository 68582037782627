import i18n from '../../i18n';

export const translations = {
  created: {
    label: i18n.t(`orders-management.cashback-status-created`),
    color: 'bg-primary-subtle text-primary',
  },

  scheduled: {
    label: i18n.t(`orders-management.cashback-status-scheduled`),
    color: 'bg-warning-subtle text-warning',
  },
  success: {
    label: i18n.t(`orders-management.cashback-status-done`),
    color: 'bg-success-subtle text-success',
  },
  done: {
    label: i18n.t(`orders-management.cashback-status-done`),
    color: 'bg-success-subtle text-success',
  },

  error: {
    label: i18n.t(`orders-management.cashback-status-erro`),
    color: 'bg-warning-subtle text-warning',
  },
  invalid: {
    label: i18n.t(`orders-management.cashback-status-erro`),
    color: 'custom-invalid',
  },

  abandoned: {
    label: i18n.t(`orders-management.cashback-status-cancelled`),
    color: 'bg-danger-subtle text-danger',
  },
  cancelled: {
    label: i18n.t(`orders-management.cashback-status-cancelled`),
    color: 'bg-danger-subtle text-danger',
  },

  expired: {
    label: i18n.t(`orders-management.cashback-status-expired`),
    color: 'bg-primary-subtle text-light',
  },
};
