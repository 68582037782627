/* eslint-disable react/prop-types */
import { useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import { Label } from 'reactstrap';
import moment from 'moment';
import useQsParams from '../../../Components/Hooks/QueryString';
import RangeDatePicker from '../../../Components/Common/RangeDatePicker';
import React from 'react';

function Date({ item, filters, t, placeholder }) {
  const { qs, setSearchParamsAsObject } = useQsParams();
  const field = qs.get(item.field)?.split(',');
  let value = [];
  const createdAtFilter = filters?.filters?.find(filter => filter.field === "created_at");

  if (field?.length) {
    value = [
      moment.unix(field[0]).format('D/M/YY'),
      moment.unix(field[1]).format('D/M/YY'),
    ];
  }


  useEffect(() => {
    if (createdAtFilter?.values?.length === 2) {
      const [startDate, endDate] = createdAtFilter.values.map(date => moment(date));
      const difference = endDate.diff(startDate, 'days');
      const field = qs.get(item.field)?.split(',');
      
      if (difference <= 90) {
        if (field?.length) {
          value = [
            moment.unix(field[0]).format("D/M/YY"),
            moment.unix(field[1]).format("D/M/YY"),
          ];
        }
        const today = moment().startOf('day');
        const thirtyDaysAgo = moment().subtract(30, 'days').startOf('day');

        setSearchParamsAsObject({
          [item.field]: `${thirtyDaysAgo.unix()},${today.unix()}`,
          page: 1,
        });
      }
    }
  }, [createdAtFilter, item.field, setSearchParamsAsObject]);

  return (
    <div className="filter-item">
      {item?.name && (
        <Label className="form-label text-muted text-uppercase fw-semibold mb-3">
          {item.name}
        </Label>
      )}

      <RangeDatePicker
        options={{ ...item?.options }}
        name={item.field}
        value={value}
        placeholder={placeholder ?? t('select-date')}
        onClear={() => {
          setSearchParamsAsObject({
            [item.field]: "", 
            page: 1,
            order: 'asc',
          });
        }}
        onChange={(v) => {
          if (v[0] && v[1]) {
            const d1 = moment(v[0]).unix();
            const d2 = moment(v[1]).unix();

            setSearchParamsAsObject({
              [item.field]: `${d1},${d2}`,
              page: 1,
              esc: 'asc',
            });
          }
        }}
      />
    </div>
  );
}

export default withTranslation()(Date);
