import i18n from '../../../i18n';

export const translations = {
  success: {
    label: i18n.t(`success`),
    color: 'bg-success-subtle text-success',
  },
  error: {
    label: i18n.t(`error`),
    color: 'bg-warning-subtle text-warning',
  },

};
