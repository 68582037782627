import React, { useEffect, useState } from 'react';
import { Routes, Route } from 'react-router-dom';

//Layouts
import NonAuthLayout from '../Layouts/NonAuthLayout';
import VerticalLayout from '../Layouts/index';

//routes
import { authProtectedRoutes, publicRoutes } from './allRoutes';
import { AuthProtected } from './AuthProtected';

const Index = () => {
  const [hasTawkToWidget, setTawkToWidget] = useState(false);

  useEffect(() => {
    if (!hasTawkToWidget) {
      window.Tawk_API = window.Tawk_API || {};
      window.Tawk_API.customStyle = { zIndex: 2 };

      (function () {
        const s1 = document.createElement('script');
        const s0 = document.getElementsByTagName('script')[0];

        s1.async = true;
        s1.src = 'https://embed.tawk.to/65e634929131ed19d974ecb0/1ho5jqnu6';
        s1.charset = 'UTF-8';
        s1.setAttribute('crossorigin', '*');
        s0.parentNode.insertBefore(s1, s0);

        setTawkToWidget(true);
      })();
    }
  }, [window?.location?.pathname, hasTawkToWidget]);

  return (
    <React.Fragment>
      <Routes>
        <Route>
          {publicRoutes.map((route, idx) => (
            <Route
              path={route.path}
              element={<NonAuthLayout>{route.component}</NonAuthLayout>}
              key={idx}
              exact={true}
            />
          ))}
        </Route>

        <Route>
          {authProtectedRoutes.map((route, idx) => (
            <Route
              path={route.path}
              element={
                <AuthProtected>
                  <VerticalLayout>{route.component}</VerticalLayout>
                </AuthProtected>
              }
              key={idx}
              exact={true}
            />
          ))}
        </Route>
      </Routes>
    </React.Fragment>
  );
};

export default Index;
