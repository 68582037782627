import React, { useEffect, useState } from 'react';
import {
  Col,
  Container,
  Row,
  Card,
  CardHeader,
  CardBody,
  Spinner,
} from 'reactstrap';

import BreadCrumb from '../../../../Components/Common/BreadCrumb';
import { ToastContainer } from 'react-toastify';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';

//redux
import TableContainer from '../../../../Components/Common/TableContainer';
import { withTranslation } from 'react-i18next';
import { formatDocument } from '../../../../helpers/format';
import { Can } from '../../../../helpers/casl';
import Search from '../../../../Components/Common/Search';
import RangeDatePicker from '../../../../Components/Common/RangeDatePicker';
import moment from 'moment';
import ExportReport from '../../../../Components/Common/ExportReport';
import useQsParams from '../../../../Components/Hooks/QueryString';
import { cleanDocument } from '../../../../helpers/functions';
import EmptyList from '../../../../Components/Common/EmptyList';

const TableShippingHistory = ({
  props,
  columns,
  method,
  isLoading,
  communication,
  meta,
  page,
  setPage,
  filtersParams,
  hiddenColumns = [],
}) => {
  const { qs, searchParams, setSearchParams, setSearchParamsAsObject, setQs } =
		useQsParams();
  const [createdAt, setCreatedAt] = useState([]);
  const animatedComponents = makeAnimated();

  const multiSelectStyles = {
    control: (provided) => ({
      ...provided,
      minWidth: '320px',
      width: 'auto',
    }),
    multiValue: (provided) => ({
      ...provided,
      backgroundColor: '#fff',
    }),
    multiValueLabel: (provided) => ({
      ...provided,
      color: '#1a2a3e',
      fontSize: '14px',
    }),
    multiValueRemove: (provided) => ({
      ...provided,
      color: '#cccccc',
      svg: {
        width: '20px',
        height: '20px',
      },
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      padding: 0,
      margin: 0,
      width: '18px',
      transition: '.2s ease-in',
      ':hover': {
        color: '#999999',
      },
    }),
  };

  const _parseCreatedAt = () => {
    const hasCreatedAt = qs.get('created_at');
    let dateRange = [];

    if (hasCreatedAt) {
      const from = hasCreatedAt?.split(',')?.[0];
      const to = hasCreatedAt?.split(',')?.[1];

      dateRange = [
        moment.unix(from).format('D/M/YY'),
        moment.unix(to || from).format('D/M/YY'),
      ];
    }

    setCreatedAt(dateRange);
  };

  useEffect(() => {
    _parseCreatedAt();
  }, [qs.get('created_at')]);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title={props.t('shipping-history')} pageTitle={props.t('communication')} />
          <Row>
            <Col lg={12}>
              <Card id="leadsList">
                <CardHeader className="border-0">
                  <Row className="row-cols-1 row-cols-sm-2 row-cols-md-12 g-2 g-lg-3">
                    <Col xl={2}>
                      <Search
                        placeholder={props.t('email-or-phone')}
                        value={qs?.get('receiver')?.replace(/%/g, '')}
                        onSubmit={({ id }) => {
                          const value = _.trim(id);
                          setSearchParamsAsObject({
                            receiver: id ? `%${value}%` : '',
                            page: 1,
                          });
                        }}
                      />
                    </Col>

                    <Col xl={2}>
                      <Search
                        inputMode="numeric"
                        placeholder={props.t('document')}
                        value={formatDocument(qs?.get('customer_document'))}
                        onSubmit={({ id }) => {
                          setSearchParamsAsObject({
                            customer_document: id ? cleanDocument(id) : '',
                            page: 1,
                          });
                        }}
                      />
                    </Col>

                    <Col xl={2}>
                      <RangeDatePicker
                        onClear={() => setSearchParams('sent_at', '')}
                        placeholder={props.t('shipping-date')}
                        value={createdAt}
                        onChange={(v) => {
                          if (v[0] && v[1]) {
                            const d1 = moment(v[0]).unix();
                            const d2 = moment(v[1]).unix();
                            const value = !d2 ? d1 : `${d1},${d2}`;
                            setSearchParams('sent_at', value);
                          }
                        }}
                      />
                    </Col>

                    <Col xl={'auto'}>
                      <Select
                        name="method"
                        id="method"
                        isClearable={qs.get('method')?.split(',').length > 1}
                        isSearchable
                        components={animatedComponents}
                        isMulti={true}
                        placeholder={props.t('shipping-method')}
                        onChange={(selectedOptions) => {
                          const values = selectedOptions
                            ?.map((option) => option.value)
                            .join(',');
                          setSearchParamsAsObject({
                            method: values,
                            page: 1,
                          });
                        }}
                        value={method?.filter((x) => {
                          const statusInUrl = qs.get('method')?.split(',');
                          return statusInUrl?.includes(x.value);
                        })}
                        options={method}
                        styles={multiSelectStyles}
                      />
                    </Col>

                    {isLoading ? (
                      <Col xl="auto">
                        <Spinner color="secondary" className="m-0" />
                      </Col>
                    ) : null}

                    <Col sm={12} md="auto" className="ms-auto">
                      <div className="hstack gap-2">
                        <button
                          type="button"
                          className="btn btn-primary"
                          onClick={() => setQs('showFilter', true)}
                          disabled={isLoading}
                        >
                          <i className="ri-filter-3-line align-bottom me-1"></i>{' '}
                          {props.t('advanced-filters')}
                        </button>
                      </div>
                    </Col>

                    <Col sm={12} md="auto">
                    </Col>
                  </Row>
                </CardHeader>

                <CardBody className="pt-0">
                  <Can I="read" a="sales_management">
                    <div>
                      {!communication?.length && qs.toString() ? (
                        <div className="mt-3">
                          <EmptyList
                            heading={props.t('search-empty-heading')}
                            body={props.t('table-content-empty-results')}
                          />
                        </div>
                      ) : (
                        <TableContainer
                          isLoading={isLoading}
                          columns={columns}
                          data={communication || []}
                          activePage={page}
                          pageSize={meta?.take || 10}
                          totalPages={meta?.pages || 0}
                          count={meta?.total || 0}
                          setPage={setPage}
                          className="custom-header-css"
                          divClass="table-responsive table-card mb-0"
                          tableClass="align-middle table-nowrap"
                          theadClass="table-light"
                          hiddenColumns={hiddenColumns}
                        />
                      )}
                    </div>
                  </Can>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>

      <ToastContainer position="top-center" closeButton={false} limit={1} />
    </React.Fragment>
  );
};

export default withTranslation()(TableShippingHistory);
