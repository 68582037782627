import { createAsyncThunk } from '@reduxjs/toolkit';
import queryString from 'qs';
import {
  getCommunicationRules as getCommunicationRulesApi,
  getCommunicationRulesByChannel as getCommunicationRulesByChannelApi,
  getCommunicationTemplates as getCommunicationTemplatesApi,
  getCommunicationTemplate as getCommunicationTemplateApi,
  updateCommunicationTemplate as updateCommunicationTemplateApi,
  updateCommunicationTemplateAssociatedRules as updateCommunicationTemplateAssociatedRulesApi,
  getTemplateVariables as getTemplateVariablesApi,
  getShippingHistory as getShippingHistoryApi,
  getShippingHistoryFilters as getShippingHistoryFiltersApi,
} from '../../helpers/bff_helper';

export const getCommunicationRules = createAsyncThunk(
  'communication/getCommunicationRules',
  async (params) => {
    try {
      const response = await getCommunicationRulesApi(params);

      return response;
    } catch (error) {
      return error;
    }
  }
);

export const getCommunicationRulesByChannel = createAsyncThunk(
  'communication/getCommunicationRulesByChannel',
  async (channel) => {
    try {
      const response = await getCommunicationRulesByChannelApi(channel);
      return response;
    } catch (error) {
      return error;
    }
  }
);

export const getCommunicationTemplates = createAsyncThunk(
  'communication/getCommunicationTemplates',
  async ({ qs, language }) => {
    try {
      const params = queryString.parse(qs);
      return await getCommunicationTemplatesApi({ ...params, language });
    } catch (error) {
      return error;
    }
  }
);

export const getCommunicationTemplate = createAsyncThunk(
  'communication/getCommunicationTemplate',
  async (id) => {
    try {
      return await getCommunicationTemplateApi(id);
    } catch (error) {
      return error;
    }
  }
);

export const updateCommunicationTemplate = createAsyncThunk(
  'communication/updateCommunicationTemplate',
  async ({ id, ...payload }) => {
    try {
      return await updateCommunicationTemplateApi(id, payload);
    } catch (error) {
      return error;
    }
  }
);

export const updateCommunicationTemplateAssociatedRules = createAsyncThunk(
  'communication/updateCommunicationTemplateAssociatedRules',
  async ({ templateId, selectedRules }) => {
    try {
      return await updateCommunicationTemplateAssociatedRulesApi(templateId, {
        ids: selectedRules,
      });
    } catch (error) {
      return error;
    }
  }
);

export const getTemplateVariables = createAsyncThunk(
  'communication/getTemplateVariables',
  async () => {
    try {
      return await getTemplateVariablesApi();
    } catch (error) {
      return error;
    }
  }
);

export const getShippingHistory = createAsyncThunk(
  'communication/getShippingHistory',
  async (params) => {
    try {
      return await getShippingHistoryApi(params);
    } catch (error) {
      return error;
    }
  }
);

export const getShippingHistoryFilters = createAsyncThunk(
  'orders/getFilters',
  async () => {
    try {
      const response = getShippingHistoryFiltersApi();
      return response;
    } catch (error) {
      return error;
    }
  }
);