/* eslint-disable react/prop-types */
import React, { } from 'react';
import { withTranslation } from 'react-i18next';
import { Row, Col } from 'reactstrap';
import Card from '../../components/Card';
import { t } from 'i18next';
import StackedChartCommunication from '../../components/StackedChartCommunication';

const SmsToken = ({ communication, buildUrlWithFilters, chartOptions, type, setActiveTab, index }) => {

  const filtersAplicatedInvoicing = {
    total: buildUrlWithFilters('/comunicacao/historico?method=Sms&page=1'),
    success: buildUrlWithFilters('/comunicacao/historico?method=Sms&status=success&page=1'),
    error: buildUrlWithFilters('/comunicacao/historico?method=Sms&status=error&page=1'),
  };


  return (
    <>
      <h5 className="mb-1">{t('results-manager.communication-summary.titles.sms-token')}</h5>

      <Row>
        <Col xs={12} md={6} xl={4}>
          {communication?.cards?.sms?.token?.length ? (
            <Card
              title={t('results-manager.communication-summary.cards.total')}
              data={communication?.cards?.sms?.token[0]}
              icon="ri-chat-private-fill text-info"
              link={filtersAplicatedInvoicing.total}
              type={type}
              index={index}
              dontNeedFormat={true}
              setActiveTab={setActiveTab}
              color="bg-info-subtle"
              label={"Total de comunicações disparadas no período."}
            />
          ) : (
            <div className="skeleton card" style={{ height: '144px' }} />
          )}
        </Col>
        <Col xs={12} md={6} xl={4}>
          {communication?.cards?.sms?.token?.length ? (
            <Card
              title={t('results-manager.communication-summary.cards.success')}
              data={communication?.cards?.sms?.token[1]}
              icon="ri-chat-private-fill text-success"
              link={filtersAplicatedInvoicing.success}
              type={type}
              index={index}
              dontNeedFormat={true}
              setActiveTab={setActiveTab}
              color="bg-success-subtle"
              label={"Total de comunicações disparadas com sucesso no período."}
            />
          ) : (
            <div className="skeleton card" style={{ height: '144px' }} />
          )}
        </Col>
        <Col xs={12} md={6} xl={4}>
          {communication?.cards?.sms?.token?.length ? (
            <Card
              title={t('results-manager.communication-summary.cards.fail')}
              data={communication?.cards?.sms?.token[2]}
              icon="ri-chat-private-fill text-danger"
              link={filtersAplicatedInvoicing.error}
              type={type}
              index={index}
              dontNeedFormat={true}
              setActiveTab={setActiveTab}
              color="bg-danger-subtle"
              label={"Total de comunicações disparadas com erro no período."}
            />
          ) : (
            <div className="skeleton card" style={{ height: '144px' }} />
          )}
        </Col>
      </Row>
      {
        chartOptions &&
        <StackedChartCommunication communication={communication} chartOptions={chartOptions} type={type} />
      }
    </>
  );
};

export default withTranslation()(SmsToken);
