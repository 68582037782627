import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import ReactApexChart from 'react-apexcharts';
import { Row, Col, Card as CardWrapper, CardBody } from 'reactstrap';

import Card from './Card';
import chartLocales from '../utils/locales';
import { convertCents, formatCurrency } from '../../../helpers/format';
import PresetsFilter from '../../../Components/Common/PresetsFilter';
import { createSelector } from 'reselect';
import { useDispatch, useSelector } from 'react-redux';
import useQsParams from '../../../Components/Hooks/QueryString';
import {
  getSalesCardsData,
  getSalesChartData,
} from '../../../slices/manager-panel/thunk';
import { getFilters } from '../../../Components/Common/Filter';
import { isEmpty } from 'lodash';

const Sales = () => {
  const dispatch = useDispatch();
  const { searchParams } = useQsParams();
  const [, setFiltersParams] = useState({});
  const [chartOptions, setChartOptions] = useState({});
  const [type, setType] = useState(searchParams.get('type'));

  const { sales } = useSelector(
    createSelector(
      (state) => state.ManagerPanel,
      ({ sales }) => ({ sales })
    )
  );

  useEffect(() => {
    getData();
  }, [dispatch, searchParams.toString()]);

  useEffect(() => {
    setChartOptions({
      chart: {
        stacked: true,
        toolbar: { show: false },
        ...chartLocales,
      },
      legend: {
        onItemHover: { highlightDataSeries: true },
        onItemClick: { toggleDataSeries: true },
      },
      markers: { size: 5 },
      stroke: { width: [1, 1, 1] },
      colors: ['#aaff00', '#ff6400', '#0e93c2'],
      labels: sales?.chart?.data?.map((i) => i?.date),
      xaxis: {
        labels: {
          formatter: (v) => v,
        },
      },
      yaxis: [
        {
          labels: {
            formatter: (v) => {
              return type === 'sales' ? v : formatCurrency(v, { convert: true });
            },
          },
          tickAmount: type != 'sales' && 10,
        },
      ],
      dataLabels: {
        enabled: false,
        style: {
          colors: ['#ffffff', '#ffffff', '#ffffff'],
        },
        formatter: (v) => {
          return v > 0 ? formatCurrency(v, { convert: true }) : '';
        },
        background: {
          enabled: true,
          padding: 4,
          borderRadius: 2,
          borderWidth: 1,
          borderColor: '#fff',
        },
      },
      tooltip: {
        shared: true,
        intersect: false,
        custom: ({ series, dataPointIndex, w }) => {
          const value1 = series[0][dataPointIndex] || 0;
          const value2 = series[1][dataPointIndex] || 0;
          const value3 = series[2][dataPointIndex] || 0;
          const totalSum = value1 + value2 + value3;

          return `
            <div class="" style="padding: 10px; background: rgba(255, 255, 255, 0.96); border: 1px solid #e3e3e3; box-shadow: 2px 2px 6px -4px #999; border-radius: 5px; font-size: 14px; color: #333;">
              <div class="" style="font-weight: 600; margin-bottom: 5px;">
                ${w.globals.labels[dataPointIndex]}
              </div>
              <div class="" style="display: flex; align-items: center; margin-bottom: 5px;">
                <span class="" style="background-color: #aaff00; width: 8px; height: 8px; border-radius: 50%; margin-right: 5px;"></span>
                <span>Impactado: <span style="font-weight: 500;"> ${type === 'sales' ? value1 : formatCurrency(value1, { convert: true })}</span></span>
              </div>
              <div class="" style="display: flex; align-items: center; margin-bottom: 5px;">
                <span class="" style="background-color: #ff6400; width: 8px; height: 8px; border-radius: 50%; margin-right: 5px;"></span>
                <span>Incremental: <span style="font-weight: 500;">${type === 'sales' ? value2 : formatCurrency(value2, { convert: true })}</span></span>
              </div>
              <div class="" style="display: flex; align-items: center; margin-bottom: 5px;">
                <span class="" style="background-color: #0e93c2; width: 8px; height: 8px; border-radius: 50%; margin-right: 5px;"></span>
                <span>Sem cashback: <span style="font-weight: 500;">${type === 'sales' ? value3 : formatCurrency(value3, { convert: true })}</span></span>
              </div>
              <hr style="margin: 8px 0;">
              <div color: #333;">Total: <span style="font-weight: 500;">${type === 'sales' ? totalSum : formatCurrency(totalSum, { convert: true })}</span></div>
            </div>
          `;
        },
      },
    });
  }, [type, sales]);

  useEffect(() => {
    const newType = searchParams.get('type');
    setType(newType);
  }, [searchParams]);

  const filters = [
    { field: 'created_at' },
    { field: 'channel' },
    { field: 'store_id' },
  ];

  const getData = () => {
    const type = searchParams.get('type');
    const params = type ? { type } : {};
    
    const appliedFilters = getFilters(filters, [
      { field: 'created_at', type: 'between_date' },
      { field: 'channel', type: 'in' },
      { field: 'store_id', type: 'in' },
    ]);


    const storesIds = appliedFilters?.store_id;
    if (storesIds) {
      sessionStorage.setItem('filterByStores', storesIds.in[0]);
    }

    if (!isEmpty(appliedFilters)) {
      params.filters = appliedFilters;
    }

    setFiltersParams(params.filters);
    dispatch(getSalesCardsData(params));
    dispatch(getSalesChartData(params));
  };

  const buildUrlWithFilters = (baseUrl) => {
    const url = new URL(baseUrl, window.location.origin);
    const appliedFilters = [
      { field: 'created_at', value: searchParams.get('created_at') },
      { field: 'channel', value: searchParams.get('channel') },
      { field: 'store_id', value: searchParams.get('store_id') },
    ];

    appliedFilters.forEach(({ field, value }) => {
      if (value) {
        url.searchParams.append(field, value);
      }
    });

   

    return url.toString();
  };
  const filtersAplicated = {
    totalRevenue: buildUrlWithFilters('/vendas?page=1&status=created,scheduled,expired,done'),
    impactedRevenue: buildUrlWithFilters('/vendas?page=1&status=created,scheduled,expired,done&generated_cashback=001%2C100000000&used_cashback=000%2C000'),
    incrementalBilling: buildUrlWithFilters('/vendas?page=1&status=created,scheduled,expired,done&used_cashback=001%2C100000000'),
    billingWithoutCashback: buildUrlWithFilters('/vendas?page=1&status=created,scheduled,expired,done&used_cashback=000%2C000&generated_cashback=000%2C000')
  };


  return (
    <div>
      <h2 className="mb-3">Vendas</h2>
      <Row>
        <Col xs={12} md={6} xl={3}>
          {sales?.cards?.total?.length ? (
            <Card
              title="Faturamento Total"
              link={filtersAplicated.totalRevenue}
              data={sales?.cards?.total}
            />
          ) : (
            <div className="skeleton card" style={{ height: '144px' }} />
          )}
        </Col>
        <Col xs={12} md={6} xl={3}>
          {sales?.cards?.total?.length ? (
            <Card
              title="Faturamento Impactado"
              link={filtersAplicated.impactedRevenue}
              data={sales?.cards?.impacted}
            />
          ) : (
            <div className="skeleton card" style={{ height: '144px' }} />
          )}
        </Col>
        <Col xs={12} md={6} xl={3}>
          {sales?.cards?.total?.length ? (
            <Card
              title="Faturamento Incremental"
              link={filtersAplicated.incrementalBilling}
              data={sales?.cards?.incremental}
            />
          ) : (
            <div className="skeleton card" style={{ height: '144px' }} />
          )}
        </Col>
        <Col xs={12} md={6} xl={3}>
          {sales?.cards?.total?.length ? (
            <Card
              title="Faturamento sem Cashback"
              link={filtersAplicated.billingWithoutCashback}
              data={sales?.cards?.without_cashback}
            />
          ) : (
            <div className="skeleton card" style={{ height: '144px' }} />
          )}
        </Col>
      </Row>

      <div style={{ marginTop: 20, marginLeft: -15 }}>
        <PresetsFilter
          header={false}
          items={[
            {
              key: 'fat',
              label: 'Faturamento',
              value: { type: 'billing' },
              isActive: type === null || type === 'billing',
            },
            {
              key: 'sales',
              label: 'Vendas',
              value: { type: 'sales' },
            },
            {
              key: 'avg',
              label: 'Ticket médio',
              value: { type: 'ticket' },
            },
          ]}
        />
      </div>

      <Row>
        <Col>
          <CardWrapper>
            <CardBody>
              {sales?.chart?.data?.length ? (
                <ReactApexChart
                  key={type}
                  type="bar"
                  height={550}
                  options={chartOptions}
                  series={[
                    {
                      name: 'Impactado',
                      type: 'bar',
                      data: sales?.chart?.data?.map((i) =>
                        type === 'sales' ? i?.value1 : Math.round(convertCents(i?.value1))
                      ),
                    },
                    {
                      name: 'Incremental',
                      type: 'bar',
                      data: sales?.chart?.data?.map((i) =>
                        type === 'sales' ? i?.value2 : Math.round(convertCents(i?.value2))
                      ),
                    },
                    {
                      name: 'Sem cashback',
                      type: 'bar',
                      data: sales?.chart?.data?.map((i) =>
                        type === 'sales' ? i?.value3 : Math.round(convertCents(i?.value3))
                      ),
                    },
                  ]}
                />
              ) : (
                <div className="skeleton card" style={{ height: 550 }} />
              )}
            </CardBody>
          </CardWrapper>
        </Col>
      </Row>
    </div>
  );
};

export default withTranslation()(Sales);
