/* eslint-disable react/prop-types */
import React, { } from 'react';
import { Row, Col } from 'reactstrap';
import Card from '../../components/Card';
import { t } from 'i18next';
import { withTranslation } from 'react-i18next';
import StackedChart from '../../components/StackedChart';


const SalesNumber = ({ sales, buildUrlWithFilters, chartOptions, type, setActiveTab, index}) => {
  const filtersAplicatedInvoicing = {
    totalRevenue: buildUrlWithFilters('/vendas?page=1&status=created,scheduled,expired,done'),
    impactedRevenue: buildUrlWithFilters('/vendas?page=1&status=created,scheduled,expired,done&generated_cashback=001%2C100000000&used_cashback=000%2C000'),
    incrementalBilling: buildUrlWithFilters('/vendas?page=1&status=created,scheduled,expired,done&used_cashback=001%2C100000000'),
    billingWithoutCashback: buildUrlWithFilters('/vendas?page=1&status=created,scheduled,expired,done&used_cashback=000%2C000&generated_cashback=000%2C000')
  };

  return (
    <>
      <h5 className="mb-1">{t('results-manager.sales-summary.titles.sales-number')}</h5>
      <Row>
        <Col xs={12} md={6} xl={3}>
          {sales?.cards?.total?.length ? (
            <Card
              title={t('results-manager.sales-summary.cards.sales-number.total')}
              link={filtersAplicatedInvoicing.totalRevenue}
              data={sales?.cards?.total[1]}
              icon="ri-shopping-bag-line text-info"
              color="bg-info-subtle"
              type={type}
              index={index}
              dontNeedFormat={true}
              setActiveTab={setActiveTab}
              label={"Quantidade de vendas válidas no período."}
            />
          ) : (
            <div className="skeleton card" style={{ height: '144px' }} />
          )}
        </Col>
        <Col xs={12} md={6} xl={3}>
          {sales?.cards?.impacted?.length ? (
            <Card
              title={t('results-manager.sales-summary.cards.sales-number.impacted-revenue')}
              link={filtersAplicatedInvoicing.impactedRevenue}
              data={sales?.cards?.impacted[1]}
              icon="ri-shopping-bag-line text-info"
              color="bg-info-subtle"
              type={type}
              index={index}
              dontNeedFormat={true}
              setActiveTab={setActiveTab}
              label={"Quantidade de vendas que tiveram apenas acúmulo de cashback no período."}
            />
          ) : (
            <div className="skeleton card" style={{ height: '144px' }} />
          )}

        </Col>
        <Col xs={12} md={6} xl={3}>
          {sales?.cards?.incremental?.length ? (
            <Card
              title={t('results-manager.sales-summary.cards.sales-number.incremental-billing')}
              link={filtersAplicatedInvoicing.incrementalBilling}
              data={sales?.cards?.incremental[1]}
              icon="ri-shopping-bag-line text-info"
              color="bg-info-subtle"
              type={type}
              index={index}
              dontNeedFormat={true}
              setActiveTab={setActiveTab}
              label={"Quantidade de vendas que tiveram resgate de crédito no período."}
            />
          ) : (
            <div className="skeleton card" style={{ height: '144px' }} />
          )}
        </Col>
        <Col xs={12} md={6} xl={3}>
          {sales?.cards?.without_cashback?.length ? (
            <Card
              title={t('results-manager.sales-summary.cards.sales-number.billing-without-cashback')}
              link={filtersAplicatedInvoicing.billingWithoutCashback}
              data={sales?.cards?.without_cashback[1]}
              icon="ri-shopping-bag-line text-info"
              color="bg-info-subtle"
              type={type}
              dontNeedFormat={true}
              index={index}
              setActiveTab={setActiveTab}
              label={"Quantidade de vendas que não tiveram resgate ou acúmulo de cashback no período."}
            />
          ) : (
            <div className="skeleton card" style={{ height: '144px' }} />
          )}
        </Col>
      </Row>

      {
        chartOptions &&
        <StackedChart sales={sales} chartOptions={chartOptions} type={type} />
      }
    </>
  )
}

export default withTranslation()(SalesNumber);
