/* eslint-disable react/prop-types */
import { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { Col, Row } from 'reactstrap';
import ReactSelect from 'react-select';
import * as _ from 'lodash';
import Date from './Date';
import React from "react";

import useQsParams from '../../../Components/Hooks/QueryString';
import SelectFilter from '../../../Components/Common/Filter/Select';

const Filter = ({ t, filters }) => {
  const { searchParams, setSearchParamsAsObject } = useQsParams();
  const [stores, setStores] = useState([]);

  useEffect(() => {
    if (sessionStorage.getItem('authUser')) {
      const obj = JSON.parse(sessionStorage.getItem('authUser'));
      setStores([...obj?.stores]);
    }
  }, []);

  const channel = [
    { label: 'Loja física', value: 'store' },
    { label: 'E-commerce', value: 'ecommerce' },
  ];

  return (
    <Row className="g-4 justify-content-end flex-column flex-xxl-row">
      <Col xl={12}>
        <Row className="g-3 row-cols-1 row-cols-md-3">
          <Col>
            <Date
              t={t}
              filters={filters}
              placeholder={t('order-date')}
              item={{ field: 'created_at' }}
            />
          </Col>

          <Col>
            <ReactSelect
              isClearable
              isSearchable
              name="channel"
              placeholder={'Selecione o canal'}
              onChange={(v) => {
                setSearchParamsAsObject({ channel: v?.value ?? '' });
              }}
              value={channel.find(
                (x) => x.value === searchParams?.get('channel')
              )}
              options={channel}
            />
          </Col>

          <Col>
            <SelectFilter
              t={t}
              key="stores"
              placeholder="Selecione a loja"
              item={{
                field: 'store_id',
                values: stores.map((store) => ({
                  label: store.name,
                  value: store.id,
                })),
              }}
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default withTranslation()(Filter);
