import { APIClient } from './api_helper';
import * as url from './url_helper';

const api = new APIClient();

const replaceParam = (url, param, value) => {
  return url.replace(param, value);
};

export const login = (user, pass) =>
  api.create(url.SESSION_LOGIN, { email: user, password: pass });

//ORDERS
export const getOrders = (params) => api.get(url.GET_ORDERS, params);
export const getOrder = (id) => api.get(replaceParam(url.GET_ORDER, ':id', id));
export const getOrdersFilters = () => api.get(url.GET_ORDERS_FILTERS);
export const getAnalyticalOrdersFilters = () => api.get(url.ANALYTICAL_ORDERS_FILTERS);
export const simulateOrder = (params) => api.create(url.SIMULATE_ORDER, params);
export const createOrder = (params) => api.create(url.CREATE_ORDER, params);
export const updateOrder = (external_id, params) =>
  api.update(
    replaceParam(url.UPDATE_ORDER, ':external_id', external_id),
    params
  );
export const approveOrder = (external_id) =>
  api.create(replaceParam(url.APPROVE_ORDER, ':external_id', external_id));
export const cancelOrder = (external_id) =>
  api.create(replaceParam(url.CANCEL_ORDER, ':external_id', external_id));

//CUSTOMERS
export const getCustomers = (params) => api.get(url.GET_CUSTOMERS, params);
export const getFilters = () => api.get(url.GET_CUSTOMERS_FILTERS);
export const getCustomer = (document, params) =>
  api.get(replaceParam(url.GET_CUSTOMER, ':document', document), params);
export const getCustomerStatement = (document, params) =>
  api.get(
    replaceParam(url.GET_CUSTOMER_STATEMENT, ':document', document),
    params
  );
export const getCustomerOperations = (document, params) =>
  api.get(
    replaceParam(url.GET_CUSTOMER_OPERATIONS, ':document', document),
    params
  );
export const getCustomerExpirations = (document, params) =>
  api.get(
    replaceParam(url.GET_CUSTOMER_EXPIRATIONS, ':document', document),
    params
  );
export const createCustomer = (customer) =>
  api.create(url.CREATE_CUSTOMER, customer);
export const updateCustomer = (document, customer) =>
  api.update(`${url.UPDATE_CUSTOMER}/${document}`, customer);
export const bulkUpdateCustomerWalletStatus = (customers_wallets, status) =>
  api.update(`${url.BULK_UPDATE_CUSTOMER_WALLET_STATUS}`, {
    customers_wallets,
    status_reason: 'a',
    status,
  });
export const batchUpdateCustomerWalletStatus = (data) =>
  api.create(`${url.BATCH_UPDATE_CUSTOMER_WALLET_STATUS}`, data, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'multipart/form-data',
    },
  });
export const sendToken = (document, params) =>
  api.create(
    replaceParam(url.SEND_CUSTOMER_TOKEN, ':document', document),
    params
  );
export const validateToken = (document, params) =>
  api.create(
    replaceParam(url.VALIDATE_CUSTOMER_TOKEN, ':document', document),
    params
  );

//STRATEGIES
export const getAccountStrategy = () => api.get(url.GET_ACCOUNT_STRATEGY);
export const updateAccountStrategy = (params) =>
  api.update(url.GET_ACCOUNT_STRATEGY, params);
export const getStrategies = (params) => api.get(url.GET_STRATEGIES, params);
export const createStrategy = (params) => api.put(url.CREATE_STRATEGY, params);
export const getBlockedProducts = (params) =>
  api.get(url.GET_BLOCKED_PRODUCTS, params);
export const batchImportBlockedProducts = (data) =>
  api.create(`${url.BATCH_IMPORT_BLOCKED_PRODUCTS}`, data, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'multipart/form-data',
    },
  });
export const batchImportCatalogStrategy = (data) =>
  api.create(`${url.BATCH_IMPORT_STRATEGY_PRODUCTS}`, data, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'multipart/form-data',
    },
  });
export const updateBlockedProduct = (externalId, data) =>
	api.update(
		replaceParam(url.UPDATE_DELETE_BLOCKED_PRODUCT, ':external_id', externalId),
		data
	);
export const deleteBlockedProduct = (externalId) =>
	api.delete(
		replaceParam(url.UPDATE_DELETE_BLOCKED_PRODUCT, ':external_id', externalId)
	);
export const getStrategyProducts = () => api.get(url.GET_STRATEGY_PRODUCTS);
export const getStrategyDetails = (filters) =>
  api.get(`${url.GET_STRATEGY_DETAILS}${filters}`);
export const deleteStrategyProducts = (data) =>
  api.delete(url.DELETE_STRATEGY_PRODUCTS, { data });
export const getStrategyFilters = () => api.get(url.GET_STRATEGY_FILTERS);

//CATALOGS
export const getProducts = (params) => api.get(url.GET_PRODUCTS, params);
export const getCategories = (params) => api.get(url.GET_CATEGORIES, params);
export const deleteProduct = (id) =>
  api.delete(replaceParam(url.DELETE_PRODUCT, ':id', id));
export const batchImportProducts = (data) =>
  api.create(`${url.BATCH_IMPORT_PRODUCTS}`, data, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'multipart/form-data',
    },
  });

//INCENTIVES
export const getIncentiveGroups = (params) =>
  api.get(url.GET_INCENTIVE_GROUPS, params);
export const getAllIncentiveGroups = (params) =>
  api.get(url.GET_ALL_INCENTIVE_GROUPS, params);
export const getIncentiveGroup = (id, params) =>
  api.get(replaceParam(url.GET_INCENTIVE_GROUP, ':groupId', id), params);
export const getIncentiveGroupUsers = (id) =>
  api.get(replaceParam(url.GET_INCENTIVE_GROUP_USERS, ':groupId', id));
export const createIncentiveGroup = (params) =>
  api.create(url.CREATE_INCENTIVE_GROUP, params);
export const createIncentive = (params) =>
  api.create(url.CREATE_INCENTIVE, params);
export const batchImportIncentiveApi = (data) =>
  api.create(`${url.IMPORT_INCENTIVE}`, data, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'multipart/form-data',
    },
  });
export const deleteGroup = (groupId) =>
  api.delete(replaceParam(url.DELETE_GROUP, ':groupId', groupId));
export const cancelIncentive = (id) =>
  api.create(replaceParam(url.CANCEL_INCENTIVE, ':id', id));
export const getSmartIncentives = () => api.get(url.GET_SMART_INCENTIVES);
export const putSmartIncentives = (id, params) =>
  api.put(replaceParam(url.PUT_SMART_INCENTIVES, ':id', id), params);
export const getIncentiveDetailsFilters = () =>
  api.get(url.GET_INCENTIVE_DETAILS_FILTERS);

//Users
export const getUsers = (params) => api.get(url.GET_USERS + params ?? '');
export const getUsersFromStore = (storeId) =>
  api.get(replaceParam(url.GET_USERS_FROM_STORE, ':id', storeId));
export const getRoles = (params) => api.get(url.GET_ROLES, params);
export const getRolesPaginated = (params) =>
  api.get(url.GET_ROLES_PAGINATED, params);
export const createUser = (params) => api.create(url.CREATE_USER, params);
export const updateUser = (id, params) =>
  api.update(replaceParam(url.UPDATE_USER, ':id', id), params);
export const updatePassword = (params) =>
  api.update(url.UPDATE_PASSWORD, params);
export const getRecoveryLink = (params) =>
  api.create(url.RECOVERY_USER, params);
export const verifyToken = (token) =>
  api.get(replaceParam(url.RECOVERY_VERIFIY_TOKEN, ':token', token));
export const createNewPassword = (params) =>
  api.update(url.RECOVERY_USER, params);

//Results
export const getHome = (params) => api.get(url.GET_HOME, params);

//Communications
export const getCommunicationRules = (params) =>
  api.get(url.GET_COMMUNICATION_RULES, params);
export const getCommunicationRulesByChannel = (channel) =>
  api.get(`${url.GET_COMMUNICATION_RULES}?channel=${channel}`);
export const getCommunicationTemplates = (params) =>
  api.get(url.GET_COMMUNICATION_TEMPLATES, params);
export const getCommunicationTemplate = (id) =>
  api.get(replaceParam(url.GET_COMMUNICATION_TEMPLATE, ':id', id));
export const updateCommunicationTemplate = (id, payload) =>
  api.put(replaceParam(url.UPDATE_COMMUNICATION_TEMPLATE, ':id', id), payload);
export const updateCommunicationTemplateAssociatedRules = (id, ids) =>
  api.create(
    replaceParam(url.UPDATE_COMMUNICATION_TEMPLATE_RULES, ':id', id),
    ids
  );
export const getCommunicationTemplateAssociatedRules = () =>
  api.get(url.GET_COMMUNICATION_RULES);
export const getTemplateVariables = () => api.get(url.GET_TEMPLATE_VARIABLES);
export const getCommunicationFilters = () => api.get(url.GET_COMMUNICATION_FILTERS);

export const getShippingHistory = (params) =>
  api.get(url.GET_SHIPPING_HISTORY, params);
export const getShippingHistoryFilters = (params) =>
  api.get(url.GET_SHIPPING_HISTORY_FILTERS, params);

//INDICATORS
export const getBillingIndicators = (params) =>
  api.get(url.GET_INDICATORS_BILLING, params);
export const getConsumersIndicators = (params) =>
  api.get(url.GET_INDICATORS_CONSUMERS, params);
export const getCheckingAccountIndicators = (params) =>
  api.get(url.GET_INDICATORS_CHECKING_ACCOUNT, params);

//STORES
export const createStore = (params) => api.create(url.CREATE_STORE, params);
export const getStores = (params) => api.get(url.GET_STORES, params);
export const getStore = (id) => api.get(replaceParam(url.GET_STORE, ':id', id));
export const getStoreUsers = (id, qs) =>
  api.get(replaceParam(url.GET_STORE_USERS + qs, ':id', id));
export const getStoreUsersAll = (id, qs) =>
  api.get(replaceParam(url.GET_STORE_USERS_ALL + qs, ':id', id));
export const patchStoreUsers = (id, users) =>
  api.update(replaceParam(url.PATCH_STORE_USERS, ':id', id), { users });
export const getStoresRaw = (params) => api.get(url.GET_STORES_RAW, params);

//REPORTS
export const reportExport = (params) => api.create(url.REPORT_EXPORT, params);

//LEAD CAPTURE
export const getLeadCaptureConfiguration = () => api.get(url.CAPTURE_WALLET);
export const setLeadCaptureConfiguration = (params) =>
  api.update(url.CAPTURE_WALLET, params);

//MY WALLET
export const getMyWalletConfiguration = () => api.get(url.MY_WALLET);
export const setMyWalletConfiguration = (params) =>
  api.put(url.MY_WALLET, params);

//INTEGRATIONS
export const getIntegrations = () => api.get(url.GET_INTEGRATIONS);
export const putIntegration = (params) => api.put(url.PUT_INTEGRATION, params);
export const getIntegrationStatus = () => api.get(url.GET_INTEGRATION_STATUS);

//GENERAL SETTINGS
export const getGeneralSettings = () => api.get(url.GET_GENERAL_SETTINGS);

export const updateGeneralSettings = (params) => async (dispatch) => {
  try {
    const response = await api.update(url.UPDATE_GENERAL_SETTINGS, params);
    dispatch({ type: 'UPDATE_GENERAL_SETTINGS_SUCCESS', payload: response });
    return response;
  } catch (error) {
    dispatch({ type: 'UPDATE_GENERAL_SETTINGS_FAILURE', payload: error });
    throw error;
  }
};
// MANAGER PANEL
export const getSalesCardsData = (params) => api.get(url.ORDERS_REPORT, params);
export const getSalesChartData = (params) =>
  api.get(url.ORDERS_REPORT_BY_DATE, params);
export const getCashbackCardsData = (params) =>
  api.get(url.ORDERS_CASHBACK_REPORT, params);
export const getCashbackChartData = (params) =>
  api.get(url.ORDERS_CASHBACK_REPORT_BY_DATE, params);
export const getCommunicationCards = (params) => api.get(url.COMMUNICATIONS_REPORT, params);

export const getCreditsCardsData = (params) =>
  api.get(url.CREDITS_REPORT, params);
export const getCommunicationCardsData = (params) => api.get(url.COMMUNICATIONS_REPORT, params);
export const getCommunicationChartsData = (params) => api.get(url.COMMUNICATIONS_REPORT_BY_DATE, params);
