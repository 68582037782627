//REGISTER
export const POST_FAKE_REGISTER = '/auth/signup';

//LOGIN
export const SESSION_LOGIN = '/session/login';

//PROFILE
export const SESSION_USER = '/me';

//ORDERS
export const GET_ORDERS = '/orders';
export const GET_ORDER = '/orders/:id';
export const GET_ORDERS_FILTERS = '/orders/filters';
export const SIMULATE_ORDER = '/orders/simulate';
export const CREATE_ORDER = '/orders';
export const UPDATE_ORDER = '/orders/:external_id';
export const APPROVE_ORDER = '/orders/:external_id/approve';
export const CANCEL_ORDER = '/orders/:external_id/cancel';

//Customers
export const GET_CUSTOMERS = '/customers';
export const GET_CUSTOMER = '/customers/:document';
export const GET_CUSTOMER_STATEMENT = '/customers/:document/statement';
export const GET_CUSTOMER_OPERATIONS = '/customers/:document/operations';
export const GET_CUSTOMER_EXPIRATIONS = '/customers/:document/expirations';
export const GET_CUSTOMERS_FILTERS = '/customers/filters';
export const CREATE_CUSTOMER = '/customers';
export const UPDATE_CUSTOMER = '/customers';
export const BULK_UPDATE_CUSTOMER_WALLET_STATUS = '/customers/status';
export const BATCH_UPDATE_CUSTOMER_WALLET_STATUS =
	'/customers/update-customers-csv';
export const SEND_CUSTOMER_TOKEN = '/customers/:document/token';
export const VALIDATE_CUSTOMER_TOKEN =
	'/customers/:document/token/authenticate';

//Strategies
export const GET_ACCOUNT_STRATEGY = '/strategies/account';
export const GET_STRATEGIES = '/strategies';
export const UPDATE_ACCOUNT_STRATEGY = '/strategies/account';
export const CREATE_STRATEGY = '/strategies/products';
export const GET_BLOCKED_PRODUCTS = '/strategies/products/blocked';
export const BATCH_IMPORT_BLOCKED_PRODUCTS =
	'/strategies/upload-block-products-csv';
export const BATCH_IMPORT_STRATEGY_PRODUCTS = '/strategies/products/massive';
export const UPDATE_DELETE_BLOCKED_PRODUCT =
	'/strategies/products/blocked/:external_id';
export const GET_STRATEGY_PRODUCTS = '/strategies/products';
export const GET_STRATEGY_DETAILS = '/strategies/details';
export const DELETE_STRATEGY_PRODUCTS = '/strategies/products';
export const GET_STRATEGY_FILTERS = '/strategies/product/filters';

//Catalogs
export const GET_PRODUCTS = '/catalogs/products';
export const GET_CATEGORIES = '/catalogs/categories';
export const BATCH_IMPORT_PRODUCTS = '/catalogs/import-catalog';
export const DELETE_PRODUCT = '/catalogs/products/:id';

//Incentives
export const GET_INCENTIVE_GROUPS = '/incentives/groups';
export const GET_ALL_INCENTIVE_GROUPS = '/incentives/groups/all';
export const GET_INCENTIVE_GROUP = '/incentives/groups/:groupId/details';
export const GET_INCENTIVE_DETAILS_FILTERS =
	'/incentives/groups/details/filters';
export const GET_INCENTIVE_GROUP_USERS = '/incentives/groups/:groupId/users';
export const CREATE_INCENTIVE_GROUP = '/incentives/groups';
export const CREATE_INCENTIVE = '/incentives';
export const IMPORT_INCENTIVE = '/incentives/massive';
export const DELETE_GROUP = '/incentives/groups/:groupId';
export const CANCEL_INCENTIVE = '/incentives/:id/cancel';
export const GET_SMART_INCENTIVES = '/smart-incentives';
export const PUT_SMART_INCENTIVES = '/smart-incentives/group/:id';

//Users
export const GET_USERS = '/users';
export const GET_ROLES = '/roles/assignable';
export const GET_ROLES_PAGINATED = '/users/roles/paginated';
export const CREATE_USER = '/users';
export const UPDATE_USER = '/users/:id';
export const UPDATE_PASSWORD = '/users/update-password';
export const RECOVERY_USER = '/users/recovery';
export const RECOVERY_VERIFIY_TOKEN = '/users/recovery/verify?token=:token';
export const GET_USERS_FROM_STORE = '/users/store/:id';

//Results
export const GET_HOME = '/home';

//Communications
export const GET_COMMUNICATION_RULES = '/communication-rules';
export const GET_COMMUNICATION_TEMPLATES = '/templates';
export const GET_COMMUNICATION_TEMPLATE = '/templates/:id';
export const UPDATE_COMMUNICATION_TEMPLATE = '/templates/:id';
export const UPDATE_COMMUNICATION_TEMPLATE_RULES =
	'/templates/:id/communication-rules';
export const GET_TEMPLATE_VARIABLES = '/templates/variables';
export const GET_SHIPPING_HISTORY = '/report/communication-history';
export const GET_SHIPPING_HISTORY_FILTERS = '/report/communication-history/filters';
export const GET_COMMUNICATION_FILTERS = '/report/communications/consolidated/filters';


//Indicators
export const GET_INDICATORS_BILLING = '/indicators/billing';
export const GET_INDICATORS_CONSUMERS = '/indicators/consumer';
export const GET_INDICATORS_CHECKING_ACCOUNT = '/indicators/checking-account';

//Stores
export const GET_STORES = '/stores';
export const GET_STORE = '/stores/:id';
export const GET_STORE_USERS = '/users/store/:id';
export const GET_STORE_USERS_ALL = '/users/store/:id/all';
export const PATCH_STORE_USERS = '/stores/:id/users';
export const GET_STORES_RAW = '/stores/raw';
export const CREATE_STORE = '/stores';

//Report
export const REPORT_EXPORT = '/report/export';
export const ORDERS_REPORT = 'report/orders';
export const ORDERS_REPORT_BY_DATE = 'report/orders/by-date';
export const ORDERS_CASHBACK_REPORT = 'report/orders/cashback';
export const ORDERS_CASHBACK_REPORT_BY_DATE = 'report/orders/cashback/by-date';
export const ANALYTICAL_ORDERS_FILTERS = 'report/analytical/orders/filters';
export const COMMUNICATIONS_REPORT = 'report/communications/consolidated';
export const CREDITS_REPORT = 'report/credits';
export const COMMUNICATIONS_REPORT_BY_DATE = 'report/communications/by-date';

//Lead Capture
export const CAPTURE_WALLET = '/capture-wallet';

//My Wallet
export const MY_WALLET = '/mywallet';

//Integrations
export const GET_INTEGRATIONS = '/integration/vtex';
export const PUT_INTEGRATION = '/integration/vtex';
export const GET_INTEGRATION_STATUS = '/integration/vtex/features';

//General Settings
export const GET_GENERAL_SETTINGS = '/settings/general';
export const UPDATE_GENERAL_SETTINGS = '/settings/general';
