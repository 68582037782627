/* eslint-disable react/prop-types */
import React, { } from 'react';
import { withTranslation } from 'react-i18next';
import General from './General';
import Sms from './Sms';
import SmsToken from './SmsToken';
import Email from './Email';
import Whatsapp from './Whatsapp';

const Summary = ({ communication, buildUrlWithFilters, setActiveTab }) => {

  return (
    <>
      <div>
        <General communication={communication} setActiveTab={setActiveTab} index={'1'} buildUrlWithFilters={buildUrlWithFilters} />
      </div>

      <div>
        <Sms communication={communication} setActiveTab={setActiveTab} index={'2'} buildUrlWithFilters={buildUrlWithFilters} />
      </div>

      <div>
        <SmsToken communication={communication} setActiveTab={setActiveTab} index={'3'} buildUrlWithFilters={buildUrlWithFilters} />
      </div>

      <div>
        <Email communication={communication} setActiveTab={setActiveTab} index={'4'} buildUrlWithFilters={buildUrlWithFilters} />
      </div>

      <div>
        <Whatsapp communication={communication} setActiveTab={setActiveTab} index={'5'} buildUrlWithFilters={buildUrlWithFilters} />
      </div>
    </>
  );
};

export default withTranslation()(Summary);
